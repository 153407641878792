import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Pagination from "../components/Pagination";
import Title from "../components/Title";
import PublicationListItem from "../components/ListItems/PublicationListItem";
import CustomBreadcrumb from "../components/CustomBreadcrumb";

const PublicationList = ({pageContext, data, location}) => {
  const {
    breadcrumb: {crumbs},
    currentPage,
    numPages,
    basePath
  } = pageContext

  return (
    <Layout>
      <Metas title="Publications"/>
      <section className="section page-breadcrumb is-small has-background-light">
        <div className="container">
            <CustomBreadcrumb crumbs={crumbs}/>
        </div>
      </section>
      <section className="section page-content">
        <div className="container">
          <Title title="Publications" className="has-text-centered"/>
          <div className="columns is-multiline">
            {data.publications.nodes.map((node, index) => (
              <div key={index} className="column is-6 is-4-desktop publication">
                <PublicationListItem content={node} />
              </div>
            ))}
          </div>
          <Pagination
            path={location.pathname}
            currentPage={currentPage}
            numPages={numPages}
            basePath={basePath}
          />
        </div>
      </section>
    </Layout>
  )
}

export default PublicationList

export const query = graphql`
    query publicationListQuery($skip: Int!, $limit: Int!) {
        publications: allNodePublication(
            sort: { fields: created, order: DESC },
            filter: {status: {eq: true}},
            limit: $limit,
            skip: $skip
        ) {
            nodes {
                title
                relationships {
                    image: field_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 335
                                    height: 473
                                    quality: 100
                                    transformOptions: {cropFocus: CENTER}
                                    layout: CONSTRAINED
                                )
                            }
                        }
                    }
                    file: field_fichier {
                      localFile {
                        publicURL
                        prettySize
                      }
                    }
                }
            }
        }
    }`
