import React from "react";
import {Link} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import A4 from "../Images/A4";
import {iconInvisibleText} from "../../Utils/functions";

const PublicationListItem = ({ content }) => (
  <div className="publication-list-item">
    <div className="columns is-gapless">
      <div className="column is-9 preview">
        {content.relationships.image ? (
            <GatsbyImage
                image={content.relationships.image.localFile.childImageSharp.gatsbyImageData}
                alt={content.title} />
        ) : (
          <A4 alt={content.title}/>
        )}
      </div>
      <div className="column is-6 content">
        <div className="content-wrapper has-background-primary has-text-white">
          <h3 className="title is-5">
            {content.title}
          </h3>
          <div className="buttons">
            <Link
              href={content.relationships.file.localFile.publicURL}
              title={`${content.title} (${content.relationships.file.localFile.prettySize})`}
              target="_blank"
              rel="noreferrer"
              download
            >
              <span className="icon is-medium">
                <i className="fas fa-lg fa-file-pdf"></i>
              </span>
              {iconInvisibleText('Télécharger')}
            </Link>
            <Link
              href={content.relationships.file.localFile.publicURL}
              title={`${content.title} (${content.relationships.file.localFile.prettySize})`}
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon is-medium">
                <i className="fas fa-lg fa-eye"></i>
              </span>
              {iconInvisibleText('Voir')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

PublicationListItem.propTypes = {
  content: PropTypes.object.isRequired,
}

export default PublicationListItem
